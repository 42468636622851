import {post,GetDocData} from "@/scripts/http"

export default {
    UploadURL: "/zone/auth/upload/res/",

    Doc(data:any) {
        const res = post(`${this.UploadURL}doc`, data);
        return res;
    },
    GetDocData(url: any) {
        const res = GetDocData(`/static/static_data/${url}.json`)
        return res
    }
}