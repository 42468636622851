<template>
  <div class="my-player">
    <video
      ref="videoElement"
      class="video-player"
      autoplay
      x5-playsinline
      playsinline
      webkitPlaysinline
      controls
      controlslist="nodownload"
      :muted="true"
      v-if="isLive"
    ></video>
    <video
      style="width: 100%; height: 100%"
      :preload="preload"
      align="center"
      :controls="controls"
      :autoplay="autoplay"
      v-else
    >
      <source :src="url" type="video/mp4" />
    </video>
  </div>
</template>

<script>
import flvjs from "flv.js";
import "video.js/dist/video-js.css";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    url: {
      type: String,
      default: "",
    },
    isLive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      player: null,
      ClearBuffer: null,
      TryCount: 0,
      playStatus: "",
      muteStatus: "",
      isMute: true,
      isPlay: false,
      preload: "auto", //  建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
      controls: true, // 确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
      autoplay: "",
    };
  },
  watch: {
    url(newVal) {
      if (newVal && this.isLive) {
        this.initPlayer();
      }
    },
  },
  // mounted() {
  //   this.initPlayer();
  // },
  beforeUnmount() {
    if (this.player !== null) {
      this.player.unload();
      this.player.detachMediaElement();
      this.player.destroy();
      this.player = null;
      clearInterval(this.ClearBuffer);
    }
  },
  methods: {
    initPlayer() {
      if (flvjs.isSupported()) {
        const videoElement = this.$refs.videoElement;
        console.log(this.$refs.videoElement);
        if (this.player !== null) {
          this.player.unload();
          this.player.detachMediaElement();
          this.player.destroy();
          this.player = null;
          clearInterval(this.ClearBuffer);
        }
        this.player = flvjs.createPlayer(
          {
            type: "flv",
            isLive: true,
            url: this.url,
            duration: 0,
            cors: true,
            autoplay: true,
            muted: true,
          },
          {
            autoplay: true,
            autoCleanupSourceBuffer: true,
            enableStashBuffer: false,
            isLive: true,
            lazyLoad: false,
            lazyLoadMaxDuration: 0,
            lazyLoadRecoverDuration: 0,
            deferLoadAfterSourceOpen: false,
            fixAudioTimestampGap: false,
          }
        );
        this.player.attachMediaElement(videoElement);
        this.player.load(); // 加载
        // this.player.play();

        this.player.muted = false;

        this.player.on(
          flvjs.Events.ERROR,
          (errorType, errorDetail, errorInfo) => {
            console.log("errorType:", errorType);
            console.log("errorDetail:", errorDetail);
            console.log("errorInfo:", errorInfo);
            //视频出错后销毁重新创建
            if (this.player) {
              this.player.pause();
              this.player.unload();
              this.player.detachMediaElement();
              this.player.destroy();
              this.player = null;
              this.createPlayer(videoElement, this.url);
            }
          }
        );
        // this.player.on("error", (err, errd) => {
        //   if (window.Config.IsDEBUG) {
        //     console.log(err, errd);
        //   }
        //   if (errd !== "CodecUnsupported" && errd !== "HttpStatusCodeInvalid") {
        //     this.initPlayer();
        //   } else {
        //     if (err === "NetworkError") {
        //       if (this.TryCount < 5) {
        //         setTimeout(() => {
        //           this.TryCount++;
        //           console.log(`错误了尝试第${this.TryCount}次`, err, errd);
        //           this.initPlayer();
        //         }, 1000);
        //       }
        //     }
        //   }
        // });

        // this.player.on("loading_complete", () => {
        //   this.Dispose();
        //   this.InitPlayer();
        // });

        // 避免时间长时间积累缓冲导致延迟越来越高;
        this.ClearBuffer = setInterval(() => {
          if (
            this.player &&
            this.player.buffered &&
            this.player.buffered.length
          ) {
            if (!this.player.buffered.length) {
              return;
            }
            const end = this.player.buffered.end(0);
            const diff = end - this.player.currentTime;
            if (diff >= 1.5) {
              this.player.currentTime = end - 0.1;
            }
          }
        }, 3 * 10 * 1000);
      }
    },
  },
});
</script>

<style lang="scss">
.my-player {
  color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .video-player {
    width: 100%;
    height: 100%;
  }
}
</style>
