<template>
  <div
    class="player"
    ref="player"
    :id="'player_' + Index"
    v-loading="loading"
    :element-loading-text="loadingText"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.7)"
    @click="ClickHandle($event)"
    @dblclick="FullScreenHandler"
  >
    <video ref="video" :id="'video_' + Index" autoplay muted preload="none" />
    <div class="clsInfo" v-if="ClsInfo">
      <div class="schInfo" :class="{ show: showInfo }">
        <div v-if="ClsInfo.ClsRoomName && ShowRoomName">
          教室：{{ ClsInfo.ClsRoomName }}
        </div>
        <div v-if="ClsInfo.DeviceName">设备：{{ ClsInfo.DeviceName }}</div>
        <div v-if="ClsInfo.IP">IP：{{ ClsInfo.IP }}</div>
        <div v-if="ClsInfo.Site">
          {{
            ClsInfo.Site === "2"
              ? "老师摄像头"
              : ClsInfo.Site === "1"
              ? "学生摄像头"
              : "课件编码器"
          }}
        </div>
        <div v-if="ClsInfo.hasCls">讲师：{{ ClsInfo.Teachers }}</div>
        <div v-if="ClsInfo.hasCls">课程：{{ ClsInfo.CourseName }}</div>
        <div v-if="ClsInfo.hasCls">班级：{{ ClsInfo.Classes }}</div>
      </div>
      <div class="vane" @click="SwitchShowInfo">
        <i class="el-icon-more" />
      </div>
    </div>
    <div class="busBar" v-if="ShowBusBar">
      <el-tooltip
        :content="!isFullScreen ? '本格全屏' : '退出全屏'"
        placement="top"
      >
        <i
          class="iconfont icon-fullscreen"
          :class="isFullScreen ? icon_fullscreen : icon_exit_fullscreen"
          @click="FullScreenHandler"
        />
      </el-tooltip>
      <!-- <el-tooltip content="打开声音" placement="top">
        <i
          class="iconfont icon_volume"
          @click="ChangeMute"
          v-if="ShowMutedBtn && !IsMuted"
        />
      </el-tooltip>
      <el-tooltip content="关闭声音" placement="top">
        <i
          class="iconfont icon_mute"
          @click="ChangeMute"
          v-if="ShowMutedBtn && IsMuted"
        />
      </el-tooltip> -->
    </div>
  </div>
</template>

<script>
import screenfull from "screenfull";
import $ from "jquery";
import flv from "flv.js";

export default {
  props: {
    DeviceID: { type: String, default: "" },
    Index: { type: Number, default: 1 },
    ClsInfo: { type: Object, default: null },
    AlertInfo: { type: Object, default: null },
    ShowInfo: { type: Boolean, default: false },
    ShowPatrolBtn: { type: Boolean, default: true },
    ShowRoomName: { type: Boolean, default: true },
    ShowMutedBtn: { type: Boolean, default: false },
    ShowBusBar: { type: Boolean, default: true },
    IsPatrol: { type: Boolean, default: false },
    ShowViewClsRoom: { type: Boolean, default: false },
  },
  watch: {
    AlertInfo(newVal) {
      if (newVal) {
        this.loading = true;
        this.loadingText = newVal.Title;
        setTimeout(() => {
          this.loading = false;
          this.loadingText = "";
        }, 1000);
      }
    },
    DeviceID(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        if (this.IsPatrol) this.Dispose();
        console.log("watch - DeviceID", this.player);
        this.source = `ws://${this.$root.SrvConfigInfo.LiveHost}:${this.$root.SrvConfigInfo.LivePort}/Patrol/${newVal}.flv`;
        if (this.IsPatrol || !this.player) {
          this.InitPlayer();
        }
      }
    },
    ClsInfo(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        if (this.IsPatrol) this.Dispose();
        console.log("watch - ClsInfo", this.player, this.IsPatrol);
        this.source = `ws://${this.$root.SrvConfigInfo.LiveHost}:${this.$root.SrvConfigInfo.LivePort}/Patrol/${newVal.ID}.flv`;
        if (this.IsPatrol || !this.player) {
          this.InitPlayer();
        }
      }
    },
    ShowInfo(newVal) {
      this.showInfo = newVal;
    },
  },
  data() {
    return {
      dataURL: "",
      dialogVisible: false,
      SyncLoading: false,
      loading: false,
      loadingText: "已经在这里播放了哦~",
      isFullScreen: false, // 是否全屏
      showInfo: this.ShowInfo,
      rules: {
        ClsRoomName: [
          { required: true, message: "请输入教室名称", trigger: "blur" },
        ],
      },
      formClsInfo: {
        RecTimer: null,
        PEID: "",
        Recing: false,
        Polling: false,
        Index: 0,
        Classes: "",
        ClsRoomName: "",
        Teachers: "",
        Lesson: "",
        Course: "",
        EvalType: 1,
        Items: [],
        Remark: "",
        EvalTime: new Date(),
      },
      IsMuted: true,
      source: "",
      player: null,
      ClearBuffer: null,
      TryCount: 0,
    };
  },
  beforeUnmount() {
    this.Dispose();
    console.log("beforeDestroy~释放播放器");
  },
  mounted() {
    // 关闭所有错误
    // flv.LoggingControl.enableAll = false;
    screenfull.on("change", () => {
      this.isFullScreen = screenfull.isFullscreen;
    });
  },
  created() {
    this.InitPlayer();
  },
  methods: {
    ViewClsRoom(ClsInfo) {
      this.$emit("ViewClsRoom", ClsInfo);
    },
    ChangeMute() {
      this.IsMuted = !this.IsMuted;
      this.Muted(this.IsMuted);
    },
    PicEvaluate() {
      const canvas = document.createElement("canvas");
      canvas.width = this.$refs.video.videoWidth;
      canvas.height = this.$refs.video.videoHeight;

      canvas
        .getContext("2d")
        .drawImage(this.$refs.video, 0, 0, canvas.width, canvas.height);

      const dataURL = canvas.toDataURL();

      this.Evaluate(1, dataURL);
    },
    RECEvaluate() {
      this.Evaluate(2);
    },
    ClickHandle(e) {
      this.$emit("click", e);
    },
    // 控制是否显示班级信息
    SwitchShowInfo() {
      this.showInfo = !this.showInfo;
    },
    // 全屏
    FullScreenHandler() {
      if (screenfull.isEnabled) {
        screenfull.toggle(this.$refs.player);
      }
    },
    // 提交录制评价
    async SubmitRecEval() {
      this.SyncLoading = true;
      this.$refs.formClsInfo.validate(async (valid) => {
        if (valid) {
          // const res = await api.Supervisor.UpdatePartor(
          //   this.formClsInfo.PEID,
          //   this.formClsInfo.ClsRoomID,
          //   this.formClsInfo.ClsRoomName,
          //   this.formClsInfo.Teachers,
          //   this.formClsInfo.Course,
          //   this.formClsInfo.Classes,
          //   this.formClsInfo.Lesson,
          //   JSON.stringify(this.formClsInfo.Items),
          //   this.formClsInfo.Remark
          // );
          // if (!res.Result) {
          //   this.$message({
          //     showClose: true,
          //     message: res.message,
          //     type: res.type,
          //   });
          // } else {
          //   this.dialogVisible = false;
          // }
        }
        this.SyncLoading = false;
      });
    },
    // 停止录制评价
    async StopRecEval() {
      this.SyncLoading = true;
      clearTimeout(this.formClsInfo.RecTimer);

      // const res = await api.Supervisor.StopRecPatorEval(
      //   this.formClsInfo.PEID,
      //   this.ClsInfo.ID
      // );

      // if (!res.Result) {
      //   this.$message({
      //     showClose: true,
      //     message: res.message,
      //     type: res.type,
      //   });
      // } else {
      //   this.formClsInfo.Recing = false;
      // }
      this.SyncLoading = false;
    },
    // 开始录制评价
    async BeginRecEval() {
      this.SyncLoading = true;
      // console.log(this.formClsInfo);
      // const res = await api.Supervisor.CreateRecPatorEval(
      //   this.formClsInfo.ClsRoomID,
      //   this.formClsInfo.ClsRoomName,
      //   this.ClsInfo.ID,
      //   this.formClsInfo.IP,
      //   this.formClsInfo.Port,
      //   this.formClsInfo.BrandCode,
      //   this.formClsInfo.ModelCode,
      //   this.formClsInfo.User,
      //   this.formClsInfo.Pwd
      // );

      // if (!res.Result) {
      //   this.$message({
      //     showClose: true,
      //     message: res.message,
      //     type: res.type,
      //   });
      // } else {
      //   this.formClsInfo.Recing = true;
      //   this.formClsInfo.PEID = res.Datas;
      //   this.RecTimer = setTimeout(this.StopRecEval, 10 * 60 * 1000);
      // }
      this.SyncLoading = false;
    },
    // 创建评价
    CreateEval() {
      this.$refs["formClsInfo"].validate(async (valid) => {
        if (valid) {
          this.SyncLoading = true;
          // const res = await api.Supervisor.CreatePartor(
          //   this.formClsInfo.EvalType,
          //   this.formClsInfo.ClsRoomID,
          //   this.formClsInfo.ClsRoomName,
          //   this.formClsInfo.Teachers,
          //   this.formClsInfo.Course,
          //   this.formClsInfo.Classes,
          //   this.formClsInfo.Lesson,
          //   JSON.stringify(this.formClsInfo.Items),
          //   this.formClsInfo.Remark,
          //   this.dataURL
          // );

          // if (!res.Result) {
          //   this.$message({
          //     showClose: true,
          //     message: res.message,
          //     type: res.type,
          //   });
          // } else {
          //   this.dialogVisible = false;
          // }
          this.SyncLoading = false;
        } else {
          return false;
        }
      });
    },
    cancleAction() {
      this.dialogVisible = false;
      this.SyncLoading = false;
    },
    // 评价对话框打开
    EvalDlgClose() {
      if (this.formClsInfo.Polling) {
        this.StartPolling(false);
      }
      if (this.formClsInfo.EvalType === 2) {
        $(this.$refs.video).prependTo($(this.$refs.player));
      }
    },
    // 打开时先加载
    async EvalDlgOpen() {
      this.formClsInfo.Items = [];
      // const res = await api.Metadata.GetMetadataByCode("ZXXKPJLX");
      // if (!res.Result) {
      //   this.$message({
      //     showClose: true,
      //     message: res.message,
      //     type: res.type,
      //   });
      // } else {
      //   if (res.Datas) {
      //     res.Datas.forEach((element) => {
      //       this.formClsInfo.Items.push({
      //         TypeName: element.MetadataName,
      //         PEType: element.MetadataCode,
      //         PEScore: 10,
      //       });
      //     });
      //   }
      // }
    },
    // 评价对话框关闭
    async EvalDlgOpened() {
      // if (this.formClsInfo.EvalType === 2) {
      //   const video = $("#video_" + this.formClsInfo.Index);
      //   const evalPlayer = $("#evalPlayer");
      //   video.css("width", "100%");
      //   video.appendTo(evalPlayer);
      //   this.formClsInfo.PEID = "";
      // }
    },
    // 评价
    Evaluate(type, dataURL) {
      if (this.ClsInfo.CSLesson) {
        this.formClsInfo.Lesson =
          "第" +
          this.ClsInfo.CSLesson +
          "节（" +
          this.ClsInfo.LessonClsTime +
          "-" +
          this.ClsInfo.LessonOutTime +
          "）";
      } else {
        this.formClsInfo.Lesson = "";
      }

      this.dialogVisible = true;
      this.formClsInfo.EvalType = type;
      this.dataURL = dataURL;
      this.formClsInfo.Classes = this.ClsInfo.Classes;
      this.formClsInfo.Course = this.ClsInfo.CourseName;
      this.formClsInfo.Teachers = this.ClsInfo.Teachers;
      this.formClsInfo.ClsRoomName = this.ClsInfo.ClsRoomName;
      this.formClsInfo.ClsRoomID = this.ClsInfo.ClsRoomID;
      this.formClsInfo.Remark = null;
      this.formClsInfo.IP = this.ClsInfo.IP;
      this.formClsInfo.Port = this.ClsInfo.Port;
      this.formClsInfo.BrandCode = this.ClsInfo.BrandCode;
      this.formClsInfo.ModelCode = this.ClsInfo.ModelCode;
      this.formClsInfo.User = this.ClsInfo.User;
      this.formClsInfo.Pwd = this.ClsInfo.Pwd;
      this.formClsInfo.Items.forEach((e) => {
        e.PEScore = 10;
      });
      // console.log(this.formClsInfo);
      // if (this.Polling) this.StopPolling();

      if (type === 2) {
        this.$nextTick(() => {
          $(this.$refs.video).css("width", "100%");
          $(this.$refs.video).appendTo($(this.$refs.evalPlayer));
        });
      }
    },
    RecError(info) {
      if (
        this.dialogVisible &&
        this.formClsInfo.ClsRoomID === info.ClsRoomID &&
        this.ClsInfo.ID === info.DeviceID
      ) {
        this.formClsInfo.Recing = false;
        this.formClsInfo.PEID = "";
        this.$message({
          showClose: true,
          message: `巡课录制失败:${info.Info}~`,
          type: "error",
          duration: 2000,
        });
      }
    },
    InitPlayer() {
      if (flv.isSupported()) {
        this.player = flv.createPlayer(
          {
            type: "flv",
            isLive: true,
            url: this.source,
            duration: 0,
          },
          {
            autoCleanupSourceBuffer: true,
            enableStashBuffer: false,
            isLive: true,
            lazyLoad: false,
            lazyLoadMaxDuration: 0,
            lazyLoadRecoverDuration: 0,
            deferLoadAfterSourceOpen: false,
            fixAudioTimestampGap: false,
          }
        );
        setTimeout(() => {
          this.player.attachMediaElement(this.$refs.video);
          console.log("???", this);
          const loadPromise = this.player.load(); // 加载
          if (loadPromise) {
            const $this = this;
            loadPromise
              .then(() => {
                $this.Play();
              })
              .catch((e) => {
                console.log("加载出错了~~", e);
              });
          } else {
            this.Play();
          }
          // if (this.Index === 0) {
          //   setTimeout(() => {
          //     this.Muted(false);
          //   }, 3000);
          // }

          this.player.on("error", (err, errd) => {
            if (
              errd !== "CodecUnsupported" &&
              errd !== "HttpStatusCodeInvalid" &&
              err !== "NetworkError"
            ) {
              console.log(err, errd);
              this.Dispose();
              this.InitPlayer();
            } else {
              if (err === "NetworkError") {
                if (this.TryCount < 20) {
                  setTimeout(() => {
                    this.TryCount++;
                    console.log(`错误了尝试第${this.TryCount}次`, err, errd);
                    this.Dispose();
                    this.InitPlayer();
                  }, 1000);
                }
              }
            }
          });

          this.player.on("loading_complete", () => {
            console.log("加载完成");
            this.Dispose();
            this.InitPlayer();
          });

          // this.player.on(flvjs.Events.MEDIA_SOURCE_CLOSE, () => {});

          // 避免时间长时间积累缓冲导致延迟越来越高
          this.ClearBuffer = setInterval(() => {
            if (this.player) {
              if (!this.player.buffered.length) {
                return;
              }
              const end = this.player.buffered.end(0);
              const diff = end - this.player.currentTime;
              if (diff >= 1.5) {
                this.player.currentTime = end - 0.1;
              }
            }
          }, 3 * 10 * 1000);
        }, 1000);
      }
    },
    // 暂停
    Pause() {
      this.player.pause();
    },
    // 播放
    Play() {
      const $this = this;
      const playPromise = this.player.play();
      if (playPromise) {
        playPromise
          .then(() => {
            // 音频加载成功
            // 音频的播放需要耗时
            // setTimeout(() => {
            //   // 后续操作
            //   this.player.play();
            // }, 1000); // audio.duration 为音频的时长单位为秒
          })
          .catch((e) => {
            setTimeout(() => {
              // 后续操作
              $this.Play();
            }, 2000); // audio.duration 为音频的时长单位为秒
            console.log("亲，你这操作有点太快了~~ 浏览器受不鸟了哦~~", e);
          });
      }
    },
    // 静音切换
    Muted(muted) {
      this.player.muted = muted;
    },
    // 释放
    Dispose() {
      if (this.player) {
        console.log("释放播放器");
        try {
          this.player.pause();
        } catch (ex) {
          console.log(ex);
        }
        this.player.unload();
        this.player.detachMediaElement();
        this.player.destroy();
        this.player = null;
        this.ClearBuffer = null;
      }
    },
  },
};
</script>

<style lang="less">
@bgColor: rgba(0, 0, 0, 0.25);
.player {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  > div:first-child {
    width: 100%;
    height: 100%;
  }

  .el-loading-mask {
    .el-icon-loading,
    .el-loading-text {
      color: #fff;
    }
  }

  video {
    height: 100%;
    width: 100%;
    background-color: black;
    object-fit: fill;
    pointer-events: none;
  }

  .el-alert {
    position: absolute;
    width: auto;
    top: 10px;
    left: 10px;
    background: rgba(240, 249, 235, 0.9);
    color: rgba(103, 194, 58, 1);
  }

  .busBar {
    display: none;
    z-index: 2;
    position: absolute;
    bottom: 0px;
    width: calc(100% - 50px);
    height: 30px;
    line-height: 30px;
    background-color: rgba(0, 0, 0, 0.25);
    color: #fff;
    text-align: right;
    padding-right: 50px;
    i {
      cursor: pointer;
      font-size: 30px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .clsInfo {
    width: 100%;
    background-color: @bgColor;
    position: absolute;
    color: #fff;
    top: 0px;
    z-index: 2;
    .schInfo {
      display: none;
      flex-wrap: wrap;
      justify-content: space-around;
      line-height: 25px;
      font-size: 9px;
      > div {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
    .show {
      display: flex;
    }
    .vane {
      font-size: 9px;
      cursor: pointer;
      position: absolute;
      background-color: @bgColor;
      border-radius: 0 0 3px 3px;
      width: 40px;
      left: 50%;
      margin-left: -20px;
      height: 10px;
      line-height: 10px;

      * {
        cursor: pointer;
      }
    }
  }
}

.player:hover {
  .busBar {
    display: block;
  }
}

.vw_2 {
  .player {
    video {
      object-fit: fill !important;
    }
  }
}
.vw_3,
.vw_4_1 {
  > div:first-child {
    .player {
      video {
        object-fit: contain !important;
      }
    }
  }
}

.patro-eval-dlg.el-dialog {
  width: 900px;
  .el-dialog__body {
    .el-form {
      #evalPlayer,
      img {
        float: left;
        width: 480px;
        height: 270px;
        background-color: #000;
        border: 1px solid #eee;
      }

      .evalClsInfo {
        float: left;
        width: calc(100% - 482px);
        height: 272px;
      }

      .el-form-item--mini.el-form-item {
        margin-bottom: 18px;
      }

      .evalInfo {
        p {
          margin: 0;
          font-size: 14px;
          line-height: 54px;
          font-weight: 700;
          text-align: left;
        }

        .evalInfoItems {
          display: grid;
          grid-template-columns: repeat(2, calc(50% - 5px));
          grid-column-gap: 10px;
        }
      }
    }
  }
  .dialog-footer {
    > span {
      line-height: 28px;
    }
  }
}
</style>
